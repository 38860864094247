import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import FlightActionMenuItem from './FlightActionMenuItem';
import appSettings from '../../../appSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import {
  IrropsCode,
  IrropsMenuItemAction,
  EntitlementNames,
  AutoRefreshStorageKeys,
  Treatment,
  RefetchPageData,
  AirlineFilter,
  ActiveKeys,
  FlightView,
} from '../../../lib/constants';
import { getIrropsMenu, isGroundEventPuck, jsonEqual, refetchDataRule } from '../../../lib/utils';
import { formatDateTime, getUtcStartEndDateTimeForOperationalTz } from '../../../lib/dateTimeUtils';
import CancelFlightForm from '../../IrregularOps/CancelFlight/CancelFlightForm/CancelFlightForm';
import ReinstateFlightForm from '../../IrregularOps/ReinstateFlight/ReinstateFlightForm/ReinstateFlightForm';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import { useRefetchPageData } from '../../../hooks/useRefetchQuery/useRefetchQuery';
import { getUtcTimeDisplayString, isNullOrWhitespace, getAirlineName } from '../../../lib/displayUtils';
import DivertFlight from '../../IrregularOps/DivertFlight/DivertFlight';
import RerouteFlight from '../../IrregularOps/RerouteFlight/RerouteFlight';
import AirTurnBack from '../../IrregularOps/AirTurnBack/AirTurnBack';
import BlockTurnBack from '../../IrregularOps/BlockTurnBack/BlockTurnBack';
import RedispatchIntermediate from '../../IrregularOps/Redispatch/RedispatchIntermediate/RedispatchIntermediate';
import RedispatchIntended from '../../IrregularOps/Redispatch/RedispatchIntended/RedispatchIntended';
import { useLocation } from 'react-router-dom';
import { ToastType } from '../../Shared/NotificationToast/NotificationToast';
import { getNextFlightLeg } from '../../../services/apiClient/flightsApi/flightsApi';
import './FlightActionMenu.css';
import UpdateIrropsReason from '../../IrregularOps/UpdateIrropsReason/UpdateIrropsReason';
import WatchFlight from '../../WatchFlight/WatchFlight';
import { deleteNote } from '../../../services/apiClient/notesApi/notesApi';
import { useNotificationUpdate } from '../../../contexts/NotificationsContext/NotificationsContext';
import { useSelectedItemDispatch } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useAutoRefreshDispatch, useAutoRefreshStore } from '../../../hooks/useAutoRefreshStore/useAutoRefreshStore';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import EquipmentChangeTool from '../../EquipmentChangeTool/EquipmentChangeTool';
import {
  deleteEquipmentChangeAck,
  postEquipmentChangeAck,
  postEquipmentChangeAckTail,
} from '../../../services/apiClient/airTrakManagerApi/airTrakManagerApi';
import AddContinueFlight from '../../IrregularOps/AddContinueFlight/AddContinueFlight';
import { NotificationStateProvider } from '../../../contexts/NotificationsContext/NotificationStateContext';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { useWindowManagerStore } from '../../../hooks/useWindowManagerStore/useWindowManagerStore';
import Flifo from '../../Flifo/Flifo';
import { useViewId } from '../../../contexts/ViewIdContext/ViewIdContext';

/**
 * @description Action Menu component for each flight leg displayed in the Flights table.
 * @param {object} flightLeg - The Flight Leg object that will be used as context for the menu items built into the menu.
 */
const FlightActionMenu = ({
  flightLeg,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
  overLayHandler,
  getFlightLineForFlightPuck,
  puckType,
  onOpenFlifoModal,
  containerRef,
}) => {
  const { trackEvent } = useAppInsightsContext();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();
  const notificationUpdate = useNotificationUpdate();
  const ellipsesRef = useRef();
  const [showModalAction, setShowModalAction] = useState('');
  const [showOverLay, setShowOverLay] = useState(false);
  const { updateAutoRefresh } = useAutoRefreshDispatch();
  const autoRefreshFlags = useAutoRefreshStore();
  const { refetchData, refetchDataFor } = useRefetchPageData();

  //Notification Toast hooks
  const [nextFlightLeg, setNextFlightLeg] = useState(null);
  // feature flag
  const { showFeature } = useFeatureFlag();
  const equipmentChangeToolFlag = showFeature(Treatment.FLIGHT_EQUIPMENT_CHANGE);
  const flifoFlag = showFeature(Treatment.FLIGHT_FLIFO);
  const melcdlFlag = showFeature(Treatment.MEL_MX_MESSAGES);
  const crewOnFlightFlag = showFeature(Treatment.CREW_ON_FLIGHT);
  const openFlightPanel = showFeature(Treatment.OPEN_FLIGHT_PANEL);
  const showContinueFlight = showFeature(Treatment.ADD_CONTINUE_FLIGHT);
  // useSwapModeStore store
  const { isSwapModeActive } = useSwapModeStore();
  const { viewWindows } = useWindowManagerStore();
  //Read query params to know the default view page
  const { search: queryParams } = useLocation();
  const currentActiveViewId = useViewId();

  const queryParamView = new URLSearchParams(queryParams).get('view');
  const melCdlTitle = melcdlFlag ? EntitlementNames.MELMXMESSAGES : 'MEL/CDL List';
  const flightLegKey = flightLeg.flightLegKey ?? flightLeg.groundEventKey;

  const hasMxMessageEntitlement = useAuthorizationAccess(null, [EntitlementNames.MXMESSAGES]);
  const hasSupportValidationEntitlement = useAuthorizationAccess(null, [EntitlementNames.SUPPORTVALIDATION]);
  const hasMxOrSupportEntitlement = hasMxMessageEntitlement || hasSupportValidationEntitlement;

  const isGroundEventPuckSelected = isGroundEventPuck(puckType);

  const onToggleHandler = (e) => {
    setShowOverLay(e);
    overLayHandler(e);
  };

  // Toggle gantt and table view state
  const viewType = queryParamView && queryParamView !== 'gantt' ? FlightView.TABLE_VIEW : FlightView.GANTT_VIEW;

  //IF DEPARTURE AND ARRIVAL NOT FOUND THEN ADD TO FLIGHTDATA
  if (flightLeg.departure === undefined && flightLeg.arrival === undefined) {
    flightLeg.departure = !!flightLeg.atd ? flightLeg.atd : flightLeg.etd;
    flightLeg.arrival = !!flightLeg.ata ? flightLeg.ata : flightLeg.eta;
  }

  /**
   * @description added next flight leg data in to current flight leg data.
   */
  const onClickHandleEllipses = async () => {
    if (nextFlightLeg === null || nextFlightLeg?.flightNumber === null || nextFlightLeg?.aircraft === null) {
      // ** add this condition on other modals
      getNextFlightLeg(
        flightLeg.flightNumber,
        flightLeg.departureDate,
        flightLeg.orig,
        flightLeg.dest,
        flightLeg.departureCount,
        flightLeg.airline,
      ).then((data) => {
        setNextFlightLeg(data);
      });
    }
  };

  /**
   * @description Constructs the URL for the Team Messaging application based on the flight leg data.
   */
  const getTeamMessagingUrl = () => {
    // Notes:
    // Deep-Linking: "&utcdate=" + (date of flightLeg.std in MM-DD-YYYY format) + "?flight=" + flightLeg.flightNumber + "&origin=" + flightLeg.orig.
    return `${appSettings.TEAM_MESSAGING_BASE_URL}?utcdate=${formatDateTime(flightLeg.std, 'MM-DD-YYYY')}
                &flight=${flightLeg.flightNumber}&origin=${flightLeg.orig}`;
  };

  const addStaticMenuGroups = (menuItems) => {
    let staticGroup = {
      featureFlagDependent: false,
      key: 'static-menu-group',
      items: [
        {
          key: `static-menu-item-${flightLegKey}-1`,
          text: 'Join Team Messaging',
          url: getTeamMessagingUrl(),
          actionType: IrropsMenuItemAction.TEAM_MESSAGING,
          cyTag: 'team-messaging',
          actionParams: {
            flightLegKey,
          },
          entitlementNeeded: '',
        },
      ],
    };
    menuItems.push(staticGroup);
  };

  const createMenuGroup = (groupTitle, isFeatureFlagDependent) => {
    return {
      key: `group-${groupTitle || 'non-descript'}-${flightLegKey}`,
      title: groupTitle,
      featureFlagDependent: isFeatureFlagDependent,
      items: [],
    };
  };

  const createMenuItem = (key, text, url, actionType, flightLegKey, entitlementNeeded) => {
    let cyTag = text.replace(/\s/g, '-').toLowerCase();
    return {
      key: key,
      text: text,
      url: url,
      cyTag: cyTag,
      actionType: actionType,
      actionParams: {
        flightLegKey: flightLegKey,
      },
      entitlementNeeded: entitlementNeeded,
    };
  };

  const isShowFeature = (featureFlagDependent, showFeature) => {
    return featureFlagDependent && !showFeature ? false : true;
  };

  // Equipment Change menu items
  const getEquipmentChangeMenuItems = () => {
    let menuItems = [];
    if (flightLeg.airline === 'AS' && viewType !== FlightView.TABLE_VIEW) {
      let equipmentChangeItem = createMenuGroup('EQUIPMENT CHANGE', true); // creating Equipment Change menu group

      equipmentChangeItem.items.push(
        createMenuItem(
          `equipment-change-tool-item-${flightLegKey}-1`,
          'Equipment Change Tool',
          '',
          'EquipmentChangeTool',
          flightLegKey,
          [EntitlementNames.EQUIPMENTCHANGE, EntitlementNames.SUPPORTVALIDATION],
        ),
      );

      const { isEquipmentChange, isEquipmentChangeAcknowledgment } = flightLeg;

      if (!isSwapModeActive) {
        if (isEquipmentChange) {
          if (isEquipmentChangeAcknowledgment) {
            equipmentChangeItem.items.push(
              createMenuItem(
                `remove-flight-ack-item-${flightLegKey}-1`,
                'Remove Flight Ack',
                '',
                'RemoveFlightAck',
                flightLegKey,
                [EntitlementNames.EQUIPMENTCHANGE],
              ),
            );
          } else {
            equipmentChangeItem.items.push(
              createMenuItem(`ack-flight-item-${flightLegKey}-1`, 'Ack Flight', '', 'AckFlight', flightLegKey, [
                EntitlementNames.EQUIPMENTCHANGE,
              ]),
            );
            equipmentChangeItem.items.push(
              createMenuItem(
                `ack-aircraft-for-day-item-${flightLegKey}-1`,
                'Ack Aircraft for Day',
                '',
                'AckAircraftForDay',
                flightLegKey,
                [EntitlementNames.EQUIPMENTCHANGE],
              ),
            );
          }
        }
      }

      menuItems.push(equipmentChangeItem);
    }

    return menuItems;
  };

  // Watch Flight Items retun menu items watch flight or un watch flight using filter Notes checks
  const getWatchFlightItems = () => {
    let watchflightItem = createMenuGroup(null, false);
    // filter Notes checks to see whether an array contains a watch flight value
    if (flightLeg.isWatchFlight) {
      watchflightItem.items.push(
        createMenuItem(`unwatch-flight-item-${flightLegKey}-1`, 'UnWatch Flight', '', 'UnWatchFlight', flightLegKey, [
          EntitlementNames.EDITWATCHFLIGHT,
        ]),
      );
    } else if (flightLeg.irropsCode !== IrropsCode.CANCELLED_FLIGHT) {
      watchflightItem.items.push(
        createMenuItem(`watch-flight-item-${flightLegKey}-1`, 'Watch Flight', '', 'WatchFlight', flightLegKey, [
          EntitlementNames.EDITWATCHFLIGHT,
          EntitlementNames.SUPPORTVALIDATION,
        ]),
      );
    }
    return watchflightItem;
  };

  const getGeneralMenuItems = () => {
    let menuItems = [];
    let generalMenuGroup = createMenuGroup(null, true);

    if (!isPaneOpen && openFlightPanel) {
      generalMenuGroup.items.push(
        createMenuItem(
          `details-menu-item-${flightLegKey}-1`,
          'Open Flight Panel',
          '',
          'OpenFlightPanel',
          flightLegKey,
          '',
        ),
      );
    }

    if (crewOnFlightFlag && flightLeg.airline !== getAirlineName(AirlineFilter.OO)) {
      generalMenuGroup.items.push(
        createMenuItem(
          `flightcrewinfo-menu-item-${flightLegKey}-1`,
          'Flight Crew Info',
          '',
          'Flight-Crew-Info',
          flightLegKey,
          '',
        ),
      );
    }

    if (flightLeg.airline !== getAirlineName(AirlineFilter.OO)) {
      generalMenuGroup.items.push(
        createMenuItem(`melcdl-menu-item-${flightLegKey}-1`, melCdlTitle, '', 'MEL-CDL-List', flightLegKey, ''),
      );
    }

    if (flifoFlag && flightLeg.irropsCode != IrropsCode.CANCELLED_FLIGHT) {
      generalMenuGroup.items.push(
        createMenuItem(`flifo-menu-item-${flightLegKey}-1`, 'FLIFO', '', 'FLIFO', flightLegKey, [
          EntitlementNames.FLIFO,
          EntitlementNames.SUPPORTVALIDATION,
        ]),
      );
    }

    menuItems.push(generalMenuGroup);

    addStaticMenuGroups(menuItems); // adding static menu items

    // get Watch Flight OR Un Watch Flight
    if (viewType === FlightView.GANTT_VIEW && !isSwapModeActive) {
      menuItems.push(getWatchFlightItems());
    }

    return menuItems;
  };

  // handle menu Item entitlements
  const handleMenuItemEntitlements = (items) => {
    return Array.from(
      new Set(items.reduce((accumalator, currentVal) => accumalator.concat(currentVal.entitlementNeeded), [])),
    );
  };

  const getIrropsMenuItems = () => {
    let menuItems = [];
    if (isSwapModeActive) {
      return [];
    }

    // Get the irrops menu
    let IrropsMenu = getIrropsMenu(flightLeg, showContinueFlight);

    if (IrropsMenu?.length > 0) {
      let irropsGroup = createMenuGroup('IRROPS', false); // creating irrops menu group

      // irropsGroup.items = IrropsMenu.map((a) => createMenuItem(a.menuText, "", a.menuActionType, flightLeg.flightLegKey));
      // irropsGroup.items.push(createMenuItem("Cancel Flights", "", IrropsMenuItemAction.CANCEL_FLIGHT, flightLeg.flightLegKey)); // RFT - Removed for Testing (AFT here)
      IrropsMenu.map((a, idx) => {
        irropsGroup.items.push(
          createMenuItem(`irrops-menu-item-${flightLegKey}-${idx}`, a.menuText, '', a.menuActionType, flightLegKey, [
            a.entitlementNeeded,
            EntitlementNames.SUPPORTVALIDATION,
          ]),
        );
        return {};
      });
      menuItems.push(irropsGroup); // adding the irrops menu group to the mainitems collection
    }

    return menuItems;
  };

  const renderGroupContent = (isFeatureEnabled, type) => {
    let menuItems = [];
    switch (type) {
      case 'IRROPS':
        menuItems = getIrropsMenuItems();
        break;
      case 'GENERAL':
        menuItems = getGeneralMenuItems();
        break;
      case 'EQUIPMENT CHANGE':
        menuItems = getEquipmentChangeMenuItems();
        break;
    }

    const renderFlightActionMenuItem = (
      item,
      idx_item,
      flightLeg,
      isGroundEventPuckSelected,
      onFlightActionMenuItemClick,
    ) => {
      if (item.entitlementNeeded) {
        return (
          <AocAuthAccessCheck
            key={`${item.key}-access-check-${idx_item}`}
            approvedAirline={flightLeg.airline}
            approvedEntitlements={item.entitlementNeeded}
          >
            {!isGroundEventPuckSelected && (
              <FlightActionMenuItem
                key={item.key}
                text={item.text}
                hrefUrl={item.url}
                actionType={item.actionType}
                actionParams={item.actionParams}
                onClickHandler={onFlightActionMenuItemClick}
                dataCyTag={item.cyTag}
              />
            )}
          </AocAuthAccessCheck>
        );
      } else if (!isGroundEventPuckSelected || item.text === melCdlTitle) {
        return (
          <FlightActionMenuItem
            key={item.key}
            text={item.text}
            hrefUrl={item.url}
            actionType={item.actionType}
            actionParams={item.actionParams}
            onClickHandler={onFlightActionMenuItemClick}
            indicatorColor={
              item.text === melCdlTitle &&
              flightLeg?.hasMaintenanceMessages &&
              melcdlFlag &&
              hasMxOrSupportEntitlement &&
              '#DE750C'
            }
            dataCyTag={item.cyTag}
          />
        );
      }
      return null;
    };

    return (
      <>
        {menuItems.map((group, idx_group) =>
          isShowFeature(group.featureFlagDependent, isFeatureEnabled) ? (
            <div key={group.key}>
              {/* This section renders a title and separator line if needed */}
              {!!group.title && (
                <AocAuthAccessCheck
                  key={`${group.key}-access-check-${idx_group}`}
                  // get list of entitlments from the groups
                  approvedAirline={flightLeg.airline}
                  approvedEntitlements={handleMenuItemEntitlements(group.items)}
                >
                  {!isGroundEventPuckSelected && (
                    <div className="menu-seperator-line" key={`menu-seperator-line-${idx_group}`}></div>
                  )}
                  {!isGroundEventPuckSelected && (
                    <div
                      className="popover-menu-header"
                      key={`popover-menu-header-${idx_group}`}
                      data-cy={`sub-menu-header-${group.title?.toLowerCase()}`}
                    >
                      {group.title}
                    </div>
                  )}
                </AocAuthAccessCheck>
              )}
              {/* This section renders each menu item */}
              {group.items.map((item, idx_item) => (
                <React.Fragment key={item.key}>
                  {renderFlightActionMenuItem(
                    item,
                    idx_item,
                    flightLeg,
                    isGroundEventPuckSelected,
                    onFlightActionMenuItemClick,
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : null,
        )}
      </>
    );
  };

  const disableBackgroundRefreshOnIrrops = (actionType) => {
    switch (actionType) {
      case IrropsMenuItemAction.CANCEL_FLIGHT:
      case IrropsMenuItemAction.REINSTATE_FLIGHT:
      case IrropsMenuItemAction.REROUTE_FLIGHT:
      case IrropsMenuItemAction.REDISPATCH_FLIGHT:
      case IrropsMenuItemAction.BLOCK_TURN_BACK:
      case IrropsMenuItemAction.DIVERT_FLIGHT:
      case IrropsMenuItemAction.AIR_TURN_BACK:
      case IrropsMenuItemAction.UPDATE_IRROPS_REASON:
      case IrropsMenuItemAction.ADD_CONTINUE_FLIGHT:
        disableBackgroundAutoRefresh();
        break;
      default:
        break;
    }
  };

  const enableBackgroundAutoRefresh = () => {
    if (!autoRefreshFlags?.ganttChartRefresh) {
      updateAutoRefresh(AutoRefreshStorageKeys.GANTT, true);
    }
  };

  const disableBackgroundAutoRefresh = () => {
    if (autoRefreshFlags?.ganttChartRefresh) {
      updateAutoRefresh(AutoRefreshStorageKeys.GANTT, false);
    }
  };

  const onFlightActionMenuItemClick = async (actionType) => {
    // Log an event to App Insights that the Team Messaging menu item was clicked.
    // Requirement to track the usage of the Team Messaging app when launched from AOC.
    trackEvent(`${actionType}`);
    ellipsesRef.current.click(); // CLOSE THE POPOVER MENU
    setShowModalAction(actionType); // SHOW THE SELECTED MODAL

    disableBackgroundRefreshOnIrrops(actionType);

    switch (actionType) {
      case 'OpenFlightPanel':
        const newFlightDetails = {
          data: flightLeg,
          isFlightPanelOpen: true,
        };

        updateSelectedFlightDetails(newFlightDetails);
        openDetailPane();
        break;
      case 'Flight-Crew-Info':
        handleChangeActivityKey(ActiveKeys.Crew);
        break;
      case 'MEL-CDL-List':
        handleChangeActivityKey(ActiveKeys.Aircraft);
        break;
      case 'UnWatchFlight':
        const saveUnWatch = await deleteNote(flightLeg.watchFlightOperationalNoteKey);
        trackEvent(`UnWatch Flight - API call to delete note is complete : ${flightLeg.flightNumber}`);

        const isSuccess = saveUnWatch?.status === 'Success';
        if (isSuccess) {
          handleModalSave(true, 'UnWatch Flight');
        } else {
          handleModalSave(false, 'UnWatch Flight');
        }
        break;
      case 'RemoveFlightAck':
        const selFlightLegDel = {
          operatingAirlineCode: flightLeg.airline,
          flightNumber: flightLeg.flightNumber,
          origin: flightLeg.orig,
          destination: flightLeg.dest,
          departureCount: flightLeg.departureCount,
          scheduledOperatingDate: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
        };
        const delAckFlight = await deleteEquipmentChangeAck(selFlightLegDel);
        trackEvent(`Equipement change tool- API call to remove acknowledge. Status:${delAckFlight?.status}`);
        const delFlightAckIsSuccess = delAckFlight?.status === 'Success';
        handleModalSave(delFlightAckIsSuccess, 'Remove Flight Ack');
        break;
      case 'AckFlight':
        const selFlightLeg = {
          operatingAirlineCode: flightLeg.airline,
          flightNumber: flightLeg.flightNumber,
          origin: flightLeg.orig,
          destination: flightLeg.dest,
          departureCount: flightLeg.departureCount,
          scheduledOperatingDate: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
        };
        const postAckFlight = await postEquipmentChangeAck(selFlightLeg);
        trackEvent(`Equipment change tool- API call to submit acknowledge. Status:${postAckFlight?.status}`);
        const ackFlightIsSuccess = postAckFlight?.status == 'Success';
        if (ackFlightIsSuccess) {
          handleModalSave(true, 'Ack Flight');
        } else {
          handleModalSave(false, 'Ack Flight');
        }
        break;
      case 'AckAircraftForDay':
        const { startDate, endDate } = getUtcStartEndDateTimeForOperationalTz(flightLeg.departure);
        const selAircraft = {
          aircraftRegistration: flightLeg.aircraft,
          startDateTimeUTC: startDate,
          endDateTimeUTC: endDate,
        };
        const postAckAircraft = await postEquipmentChangeAckTail(selAircraft);
        trackEvent(`Equipment change tool- API call to submit acknowledge for tail. Status:${postAckAircraft?.status}`);
        const ackAircraftIsSuccess = postAckAircraft?.status == 'Success';
        const successMsg = `Acknowledge for day submitted for aircraft ${flightLeg.aircraft}`;
        const failMsg = `Failed to submit acknowledge for day for aircraft ${flightLeg.aircraft}`;
        handleModalSave(ackAircraftIsSuccess, 'Ack Aircraft For Day', ackAircraftIsSuccess ? successMsg : failMsg);
        break;
      default:
        // DO SOMETHING
        break;
    }
  };
  const handleModalCancel = (actionType) => {
    // GOT CANCEL SIGNAL FROM THE MODAL (actionType = 'CXL | RIN ... ')
    setShowModalAction(''); // RESET THE SELECTED MODAL ACTION. THIS WILL REMOVE THE MODAL DIALOG
    setNextFlightLeg(null); // Reset Next Flight state on Submit
    if (!isSwapModeActive) {
      // enabling background refetch and refresh only if not in swap mode
      enableBackgroundAutoRefresh();
      setTimeout(() => {
        refetchData(RefetchPageData.GANTT, true);
        refetchData(RefetchPageData.FLIGHT_LIST_TABLE, true);
      }, 1000);
    }
  };

  /**
   * @description this function will look at irrops refresh enhancement flag and consider the actionType to decide how to redirect the call.
   * @param {bool} result
   * @param {string} actionText what action has been performed
   * @param {string} customMsg
   * @param {*} checkFlightExistsArr
   */
  const handleModalSave = (result, actionText, customMsg = '', checkFlightExistsArr = []) => {
    let isUsingCheckFlightExistsCall =
      actionText !== 'Watch Flight' &&
      actionText !== 'UnWatch Flight' &&
      actionText !== 'Updated Irrops Reason' &&
      actionText !== 'Ack Flight' &&
      actionText !== 'Remove Flight Ack' &&
      actionText !== 'Ack Aircraft For Day' &&
      actionText !== 'Flifo Info & Schedule';

    if (isUsingCheckFlightExistsCall) {
      handleModalSaveV2(result, actionText, customMsg, checkFlightExistsArr);
    } else {
      handleModalSaveV1(result, actionText, customMsg);
    }
  };

  /**
   * Updates the notification based on the result and action text.
   */
  const updateNotification = (result, actionText, customMsg, flightLeg, notificationUpdate) => {
    let toastMessageText = '';

    if (result) {
      toastMessageText = `${actionText} was submitted for Flight ${flightLeg.flightNumber}. Checking status...`;
      notificationUpdate(ToastType.SUCCESS, toastMessageText, true, false);
    } else {
      toastMessageText = !isNullOrWhitespace(customMsg)
        ? customMsg
        : `Failed to submit ${actionText.toLowerCase()} for Flight ${flightLeg.flightNumber}. Please try again`;
      notificationUpdate(ToastType.ERROR, toastMessageText, true, false);
    }

    return toastMessageText;
  };

  /**
   * Processes head calls to check flight existence and returns a success flag.
   */
  const processHeadCalls = async (checkFlightExistsArr) => {
    const headCallsArr = await Promise.all(checkFlightExistsArr);
    return headCallsArr.every((call) => call?.status === 200);
  };

  /**
   * Refreshes data based on the current view and state.
   */
  const refreshData = (viewWindows, isPaneOpen, summaryPanelMode, refetchData, refetchDataFor) => {
    const windowView = viewWindows.find((window) => window.id === currentActiveViewId.id);
    if (windowView?.viewType === FlightView.GANTT) {
      refetchData(RefetchPageData.GANTT, true);
    }
    if (windowView?.viewType === FlightView.TABLE_LIST) {
      refetchData(RefetchPageData.FLIGHT_LIST_TABLE, true);
    }

    setTimeout(() => {
      if (windowView?.viewType === FlightView.GANTT) {
        refetchData(RefetchPageData.GANTT, true);
      }
      refetchData(RefetchPageData.FLIGHT_DETAIL_PANE_ACTIVITY_LOG, true);
      refetchDataFor(refetchDataRule(isPaneOpen, summaryPanelMode));
    }, 5000);
  };

  /**
   * Refactored handleModalSaveV2 function.
   */
  const handleModalSaveV2 = async (result, actionText, customMsg = '', checkFlightExistsArr = []) => {
    const toastMessageText = updateNotification(result, actionText, customMsg, flightLeg, notificationUpdate);

    if (result) {
      const headCheckFlightsCallsSucceeded = await processHeadCalls(checkFlightExistsArr);

      if (headCheckFlightsCallsSucceeded) {
        notificationUpdate(ToastType.SUCCESS, '', false, true);
        refreshData(viewWindows, isPaneOpen, summaryPanelMode, refetchData, refetchDataFor);
        setShowModalAction('');
        setNextFlightLeg(null);
      } else {
        notificationUpdate(
          ToastType.ERROR,
          `Failed to submit ${actionText.toLowerCase()} for Flight ${flightLeg.flightNumber}. Please try again`,
          true,
          false,
        );
      }
    } else {
      setShowModalAction('');
      setNextFlightLeg(null);
    }

    trackEvent(`${actionText} : Notification Toast set with the message - ${toastMessageText}`);
    enableBackgroundAutoRefresh();
  };

  const handleModalSaveV1 = (result, actionText, customMsg = '') => {
    // GOT SAVE SIGNAL FROM THE MODAL (actionText = 'Cancel | Reinstate ...')
    let toastMessageText = '';

    if (result) {
      toastMessageText = !isNullOrWhitespace(customMsg)
        ? customMsg
        : `${actionText} was submitted for Flight ${flightLeg.flightNumber}`;
      notificationUpdate(ToastType.SUCCESS, toastMessageText, true, true); // SHOW TOAST NOTIFICATION
      setTimeout(() => {
        if (
          actionText === 'Watch Flight' ||
          actionText === 'UnWatch Flight' ||
          actionText === 'Ack Flight' ||
          actionText === 'Remove Flight Ack' ||
          actionText === 'Ack Aircraft For Day' ||
          actionText === 'Flifo Info & Schedule'
        ) {
          refetchData(RefetchPageData.GANTT, true);
          refetchData(RefetchPageData.FLIGHT_DETAIL_PANE_ACTIVITY_LOG, true);
        }

        // Refetch some queries based on fetching rules for the page.
        refetchDataFor(refetchDataRule(isPaneOpen, summaryPanelMode));
      }, 3000);
    } else {
      // CHECK CUSTOM MESSAGE IS NULL THEN SHOW MESSAGE
      toastMessageText = !isNullOrWhitespace(customMsg)
        ? customMsg
        : `Failed to submit ${actionText.toLowerCase()} for Flight ${flightLeg.flightNumber} Please try again`;

      if (actionText !== 'Flifo Info & Schedule') {
        notificationUpdate(ToastType.ERROR, toastMessageText, true, false);
        setShowModalAction(''); // RESET THE SELECTED MODAL ACTION.
        setNextFlightLeg(null); // Reset Next Flight state on Submit
      }
    }
    trackEvent(`${actionText} : Notification Toast set with the message - ${toastMessageText}`);
    enableBackgroundAutoRefresh();
  };
  /**
   * @description this function will be called only when Irrops refresh enhancement flag is disabled
   * @param {bool} result
   * @param {string} actionText what action has been performed
   * @param {string} customMsg
   */

  const getModalContent = () => {
    let flightDetails = {
      operatingAirlineCode: flightLeg.airline,
      flightNumber: flightLeg.flightNumber,
      origin: flightLeg.orig,
      destination: flightLeg.dest,
      scheduledOperatingDateUTC: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
      legNumber: flightLegKey,
      flightDateUtc: `${formatDateTime(flightLeg.departureDate, 'MMMM DD')}`,
      aircraftRegistration: flightLeg.aircraft,
      scheduledDestination: flightLeg.scheduledDestination,
      isEtopsFlight: flightLeg.isEtopsFlight,
      departureDate: flightLeg.departureDate,
      departureCount: flightLeg.departureCount,
    };

    switch (
      showModalAction // DYNAMICALLY OPEN ANY MODAL BASED ON THE ACTION MENU ITEM SELECTED
    ) {
      case IrropsMenuItemAction.CANCEL_FLIGHT: // OPEN CANCEL FLIGHT MODAL HERE..
        return (
          <CancelFlightForm
            onCancel={handleModalCancel}
            onSave={handleModalSave}
            currentFlightCancel={{
              operatingAirlineCode: flightLeg.airline,
              flightNumber: flightLeg.flightNumber,
              origin: flightLeg.orig,
              destination: flightLeg.dest, //Actual Destination
              scheduledDestination: flightLeg.scheduledDestination, //Scheduled Destination
              flightDateUtc: `${formatDateTime(flightLeg.departureDate, 'MMM DD')}`,
              scheduledOperatingDateUTC: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
              aircraftRegistrationNumber: flightLeg.aircraft,
              legNumber: flightLegKey,
              departureCount: flightLeg.departureCount,
            }}
          />
        );
      case IrropsMenuItemAction.REINSTATE_FLIGHT: // OPEN REINSTATE FLIGHT MODAL HERE..
        return (
          <ReinstateFlightForm
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            reinstateFlight={{
              operatingAirlineCode: flightLeg.airline,
              flightNumber: flightLeg.flightNumber,
              origin: flightLeg.orig,
              destination: flightLeg.dest,
              scheduledDestination: flightLeg.scheduledDestination,
              scheduledOperatingDateUTC: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
              legNumber: flightLegKey,
              flightDateUtc: `${formatDateTime(flightLeg.departureDate, 'MMM DD')}`,
              aircraftRegistration: flightLeg.aircraft,
              departure: getUtcTimeDisplayString(flightLeg.departure),
              arrival: getUtcTimeDisplayString(flightLeg.arrival),
              departureCount: flightLeg.departureCount,
              // flightLegKey:flightLeg.flightLegKey,
            }}
          />
        );
      case IrropsMenuItemAction.DIVERT_FLIGHT: // OPEN DIVERT FLIGHT MODAL HERE
        return (
          <DivertFlight
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            divertFlight={{
              outTime: flightLeg.atd,
              eta: getUtcTimeDisplayString(flightLeg.eta),
              ...flightDetails,
            }}
            nextFlightLeg={nextFlightLeg}
            getFlightLineForFlightPuck={getFlightLineForFlightPuck}
          />
        );
      case IrropsMenuItemAction.REROUTE_FLIGHT: // OPEN DIVERT FLIGHT MODAL HERE
        return (
          <RerouteFlight
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            rerouteFlight={{
              etd: flightLeg.etd,
              eta: getUtcTimeDisplayString(flightLeg.eta),
              ...flightDetails,
            }}
            nextFlightLeg={nextFlightLeg}
            getFlightLineForFlightPuck={getFlightLineForFlightPuck}
          />
        );
      case IrropsMenuItemAction.ADD_CONTINUE_FLIGHT: // OPEN ADD CONTINUE FLIGHT MODAL HERE
        return (
          <AddContinueFlight
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            addContinueFlight={{
              outTime: flightLeg.atd,
              etd: flightLeg.atd ?? flightLeg.etd ?? flightLeg.std,
              eta: getUtcTimeDisplayString(flightLeg.ata ?? flightLeg.eta),
              ...flightDetails,
            }}
            nextFlightLeg={nextFlightLeg}
            irropsCode={flightLeg.irropsCode}
            getFlightLineForFlightPuck={getFlightLineForFlightPuck}
          />
        );
      case IrropsMenuItemAction.AIR_TURN_BACK: // OPEN AIR TURN BACK MODAL HERE
        return (
          <AirTurnBack
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            airTurnBackDetails={{
              outTime: flightLeg.atd,
              eta: getUtcTimeDisplayString(flightLeg.eta),
              ...flightDetails,
            }}
            getFlightLineForFlightPuck={getFlightLineForFlightPuck}
          />
        );
      case IrropsMenuItemAction.BLOCK_TURN_BACK:
        return (
          <BlockTurnBack
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            blockTurnBackDetails={{
              outTime: flightLeg.atd ?? flightLeg.etd ?? flightLeg.std,
              eta: getUtcTimeDisplayString(flightLeg.eta),
              ...flightDetails,
            }}
            getFlightLineForFlightPuck={getFlightLineForFlightPuck}
          />
        );
      case IrropsMenuItemAction.REDISPATCH_FLIGHT:
        if (flightLeg.projectedDestination === null || flightLeg.projectedDestination === flightLeg.dest) {
          return (
            <RedispatchIntermediate
              onClose={handleModalCancel}
              onCommit={handleModalSave}
              redispatchDetails={{
                etd: flightLeg.etd,
                eta: getUtcTimeDisplayString(flightLeg.eta),
                ...flightDetails,
              }}
            />
          );
        } else if (
          flightLeg.irropsCode === IrropsCode.REDISPATCH_FLIGHT &&
          flightLeg.projectedDestination !== null &&
          flightLeg.projectedDestination !== flightLeg.dest
        ) {
          return (
            <RedispatchIntended
              onClose={handleModalCancel}
              onCommit={handleModalSave}
              reDispatchDetails={{
                etd: flightLeg.etd,
                eta: getUtcTimeDisplayString(flightLeg.eta),
                projectedDestination: flightLeg.projectedDestination,
                ...flightDetails,
              }}
            />
          );
        }
        break;
      case IrropsMenuItemAction.UPDATE_IRROPS_REASON:
        return (
          <UpdateIrropsReason
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            selectedFlightLeg={{
              operatingAirlineCode: flightLeg.airline,
              flightNumber: flightLeg.flightNumber,
              origin: flightLeg.orig,
              destination: flightLeg.dest,
              flightDateUtc: `${formatDateTime(flightLeg.departureDate, 'MMM DD')}`,
              aircraftRegistration: flightLeg.aircraft,
              selirropsCode: flightLeg.irropsCode,
              departureDate: flightLeg.departureDate,
              departureCount: flightLeg.departureCount,
            }}
          />
        );
      case 'WatchFlight':
        return (
          <WatchFlight
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            selectedFlightLeg={{
              operatingAirlineCode: flightLeg.airline,
              flightNumber: flightLeg.flightNumber,
              origin: flightLeg.orig,
              destination: flightLeg.dest,
              flightDateUtc: `${formatDateTime(flightLeg.departureDate, 'MMM DD')}`,
              aircraftRegistration: flightLeg.aircraft,
              departureCount: flightLeg.departureCount,
              scheduledOperatingDateUTC: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
            }}
          />
        );
      case 'EquipmentChangeTool':
        return (
          <EquipmentChangeTool
            onClose={handleModalCancel}
            onCommit={handleModalSave}
            isEquipmentChangeAcknowledgment={flightLeg?.isEquipmentChangeAcknowledgment}
            isEquipmentChange={flightLeg?.isEquipmentChange}
            selectedFlightLeg={{
              operatingAirlineCode: flightLeg.airline,
              flightNumber: flightLeg.flightNumber,
              origin: flightLeg.orig,
              destination: flightLeg.dest,
              departureCount: flightLeg.departureCount,
              departure: flightLeg.departure,
              aircraftRegistration: flightLeg.aircraft,
              scheduledOperatingDate: `${formatDateTime(flightLeg.departureDate, 'YYYY-MM-DD')}`,
            }}
          />
        );
      case 'FLIFO':
        if (onOpenFlifoModal) {
          onOpenFlifoModal(flightLeg, handleModalSave, handleModalCancel);
          return null;
        } else return <Flifo onClose={handleModalCancel} onCommit={handleModalSave} flightLeg={flightLeg} />;
      default:
        // NO KNOWN ACTION TO PERFORM
        return null;
    }
  };

  const popperConfig = {
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          fallbackPlacements: ['top-end', 'bottom-end'],
        },
      },
    ],
  };

  return (
    <NotificationStateProvider>
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        container={containerRef}
        placement={'bottom-end'}
        popperConfig={popperConfig}
        onToggle={onToggleHandler}
        overlay={
          <Popover className="popover-flight-action-menu" id={`popover-menu-flightlegkey-${flightLegKey}`}>
            <Popover.Content bsPrefix="flight-list-table-action-menu-popover">
              {
                <>
                  {/*  {render Detail Pane Open Button } */}
                  {renderGroupContent(true, 'GENERAL')}
                  {/*  {render Irrops Menu Items } */}
                  {renderGroupContent(true, 'IRROPS')}
                  {/*  {render Equipment Change Menu Items } */}
                  {renderGroupContent(equipmentChangeToolFlag, 'EQUIPMENT CHANGE')}
                </>
              }
            </Popover.Content>
          </Popover>
        }
      >
        <div
          className={`action-menu ${showOverLay ? 'show-overlay' : ''}`}
          onClick={onClickHandleEllipses}
          ref={ellipsesRef}
          data-cy={`actionmenu-flightlegkey-${flightLegKey}`}
        >
          <FontAwesomeIcon className="menu-action-ellipsis" icon={faEllipsisH} />
        </div>
      </OverlayTrigger>
      {showModalAction.length > 0 ? getModalContent() : null}
    </NotificationStateProvider>
  );
};

FlightActionMenu.propTypes = {
  flightLeg: PropTypes.object,
  openDetailPane: PropTypes.func,
  isPaneOpen: PropTypes.bool,
  overLayHandler: PropTypes.func,
  summaryPanelMode: PropTypes.string,
  handleChangeActivityKey: PropTypes.func,
  getFlightLineForFlightPuck: PropTypes.func,
  puckType: PropTypes.string,
  onOpenFlifoModal: PropTypes.func,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

FlightActionMenu.defaultProps = {
  overLayHandler: () => {},
};

export default withAppInsightsTracking(
  React.memo(FlightActionMenu, (prevProps, nextProps) => {
    return (
      jsonEqual(prevProps.flightLeg, nextProps.flightLeg) &&
      prevProps.isPaneOpen === nextProps.isPaneOpen &&
      prevProps.summaryPanelMode === nextProps.summaryPanelMode
    );
  }),
);
